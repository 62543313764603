export const filterConfig = [
  {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: true, High: true, Medium: false, Low: false, Informational: false
      }
    },
  },
  {
    Source: {
      filter_name: "source",
      isOpen: true,
      filters: {'Endpoint': false, 'Cloud': false, 'Network': false, 'Mobile': false, 'Identity': false}
    }
  },
  {
    Cloud: {
      filter_name: "type",
      isOpen: true,
      filters: {'AWS': false, 'Gsuite': false, 'Salesforce': false, 'O365': false}
    }
  },
  {
    Action: {
      filter_name: "action",
      isOpen: true,
      filters: {'Logged': false, 'Alerted': false, 'Blocked': false, 'Killed': false, 'Quarantine': false, 'Scanned': false}
    }
  }
]

export const emptyFilterConfig = [
  {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: false, High: false, Medium: false, Low: false, Informational: false
      }
    },
  },
  {
    Source: {
      filter_name: "source",
      isOpen: true,
      filters: {'Endpoint': false, 'Cloud': false, 'Network': false, 'Mobile': false, 'Identity': false}
    }
  },
  {
    Cloud: {
      filter_name: "type",
      isOpen: true,
      filters: {'AWS': false, 'Gsuite': false, 'Salesforce': false, 'O365': false}
    }
  },
  {
    Action: {
      filter_name: "action",
      isOpen: true,
      filters: {'Logged': false, 'Alerted': false, 'Blocked': false, 'Killed': false, 'Quarantine': false, 'Scanned': false}
    }
  }
]

export const resetFilterConfig = {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: true, High: true, Medium: false, Low: false, Informational: false
      }
    },
    Source: {
      filter_name: "source",
      isOpen: true,
      filters: {'Endpoint': false, 'Cloud': false, 'Network': false, 'Mobile': false, 'Identity': false}
    },
    Cloud: {
      filter_name: "type",
      isOpen: true,
      filters: {'AWS': false, 'Gsuite': false, 'Salesforce': false, 'O365': false}
    },
    Action: {
      filter_name: "action",
      isOpen: true,
      filters: {'Logged': false, 'Alerted': false, 'Blocked': false, 'Killed': false, 'Quarantine': false, 'Scanned': false}
    }
}

export const resetEmptyFilterConfig = {
  Severity: {
    filter_name: "severity",
    isOpen: true,
    filters: {
      Critical: false, High: false, Medium: false, Low: false, Informational: false
    }
  },
  Source: {
    filter_name: "source",
    isOpen: true,
    filters: {'Endpoint': false, 'Cloud': false, 'Network': false, 'Mobile': false, 'Identity': false}
  },
  Cloud: {
    filter_name: "type",
    isOpen: true,
    filters: {'AWS': false, 'Gsuite': false, 'Salesforce': false, 'O365': false}
  },
  Action: {
    filter_name: "action",
    isOpen: true,
    filters: {'Logged': false, 'Alerted': false, 'Blocked': false, 'Killed': false, 'Quarantine': false, 'Scanned': false}
  }
}

export default filterConfig
