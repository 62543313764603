import {
  getVulnerabilityScans,
  addVulnerabilityScan,
  deleteVulnerabilityScan,
  getTenant,
  getAvailableScanDays,
  editVulnerabilityScan,
} from "../../../api/ApiClient";
import { formatTimestamp } from "../../../util/format";
import { captureSentryError } from "../../../util/sentry";

export const verifySubmission = ({
       setSubmitError,
       setSubmitLoading,
       showDateResponseModal,
       setShowDateResponseModal,
       currTab,
       externalInput,
       internalInput,
       selectedVM
}) => {
  setSubmitError("");
  setSubmitLoading(true);
  setShowDateResponseModal(false);
  const currTabInput = currTab === "external_scans" ? externalInput : internalInput;
  if (currTab === "internal_scans" && !selectedVM) {
    setSubmitError("Must choose a VM");
  } else if (!currTabInput) {
     setSubmitError("Empty input");
  } else if(showDateResponseModal) {
    setShowDateResponseModal(true);
  }
  setSubmitLoading(false);
};

export const handleAdd = async ({
  setSubmitError,
  setSubmitLoading,
  setShowDateResponseModal,
  currTab,
  externalInput,
  internalInput,
  setExternalInput,
  setInternalInput,
  selectedVM,
  userData,
  setSelectedVM,
  handleFetchData,
  defaultDateSelection,
}) => {
  setSubmitError("");
  setSubmitLoading(true);
  const currTabInput =
    currTab === "external_scans" ? externalInput : internalInput;
  const setInput =
    currTab === "external_scans" ? setExternalInput : setInternalInput;
  const arrayOfLines = currTabInput
      .split("\n")
      .filter((val) => val.length)
      .map((val) => val.trim());
  try {
    const params = {
      scan_type: currTab === "external_scans" ? "external" : "internal",
      ips: arrayOfLines,
      scan_day: defaultDateSelection
    };
    if (currTab === "internal_scans") params["hostname"] = selectedVM;
    await addVulnerabilityScan(userData.tenant, params);
    setInput("");
    setSelectedVM("");
    handleFetchData();
  } catch (e) {
    setSubmitError(e.message);
    captureSentryError(e, userData, "addVulnerabilityScan API in /Onboarding/VulnerabilityScan/util.js");
  }
  setSubmitLoading(false)
  setShowDateResponseModal(false)
};

export const handleDelete = async ({
  id,
  setRowError,
  setRowLoading,
  userData,
  resetPendingStages,
  handleFetchData,
}) => {
  setRowError("");
  setRowLoading(true);
  try {
    await deleteVulnerabilityScan(userData.tenant, id);
    resetPendingStages();
    handleFetchData();
  } catch (e) {
    setRowError(e.message);
    captureSentryError(e, userData, "deleteVulnerabilityScan API in /Onboarding/VulnerabilityScan/util.js");
  }
  setRowLoading(false);
};

export const getScanDays = async ({
  userData,
  setScanDayOptions,
  setScanDayFetchError,
}) => {
  try {
    const results = await getAvailableScanDays(userData.tenant);
    setScanDayOptions(
      results?.days
        .map((num) => {
          const retVal = [];
          retVal[num.toString()] = false;
          return retVal;
        })
    );
  } catch (e) {
    setScanDayFetchError("An error has occurred");
    captureSentryError(e, userData, "getAvailableScanDays API in /Onboarding/VulnerabilityScan/util.js");
  }
};

export const getVulnerabilityScanData = async ({
  setFetchError,
  setLoading,
  userData,
  setData,
  setShowInternalInputs,
  setShowExternalInputs,
  setVirtualMachines,
  setDefaultDateSelection
}) => {
  try {
    setFetchError(false);
    setLoading(true);
    const response = await getVulnerabilityScans(userData.tenant);
    setData(response?.results);
    const internalScans = response.results?.internal_scans;
    const externalScans = response.results?.external_scans;
    !externalScans.length ? setShowExternalInputs(true) : setDefaultDateSelection(externalScans[0]['scan_day_of_month']);
    !internalScans.length ? setShowInternalInputs(true) : setDefaultDateSelection(internalScans[0]['scan_day_of_month'])
    const existingVMScans = internalScans?.map(
      (scan) => scan.vm_hostname
    );
    await getVirtualMachines({
      existingVMScans,
      userData,
      setVirtualMachines
    });
    setLoading(false);
  } catch (e) {
    captureSentryError(e, userData, "getVulnerabilityScans API in /Onboarding/VulnerabilityScan/util.js");
    setFetchError(true);
  }
};

const getVirtualMachines = async ({
  existingVMScans,
  userData,
  setVirtualMachines
}) => {
  try {
    let tenantVMData = await getTenant(userData?.tenant);
    const hostnames = tenantVMData?.vm_tracking
      ?.filter((vm) => !existingVMScans.includes(vm.hostname))
      .map((vm) => {
        const retVal = [];
        retVal[vm.hostname] = false;
        return retVal;
      });
    setVirtualMachines(hostnames);
  } catch (e) {
    captureSentryError(e, userData, "getTenant API in /Onboarding/VulnerabilityScan/util.js");
  }
};

export const timeOptions = () => {
  let options = []
  for (var i = 0; i < 24; ++i) {
    let hour = i.toString()
    if (hour.length === 1) {
      hour = `0${i}`
    }
    options.push(`${hour}:00`)
    options.push(`${hour}:30`)
  }
  return options
}

export const handleEdit = async ({
  setEditError,
  setEditLoading,
  editInput,
  selectedDay,
  userData,
  modalData,
  handleFetchData,
  setEditInput,
  setModalData,
  setEditing,
  scanTime,
  scanTimezone
}) => {
  setEditError("");
  setEditLoading(true);
  if (editInput) {
    const arrayOfLines = editInput
      .split("\n")
      .filter((val) => val.length)
      .map((val) => val.trim());
    try {
      const params = {
        ips: arrayOfLines,
        scan_date: formatTimestamp(selectedDay, scanTime, scanTimezone),
        timezone: scanTimezone
      };
      await editVulnerabilityScan(userData.tenant, params, modalData.id);
      handleFetchData();
      setEditInput("");
      setModalData();
      setEditing(false);
    } catch (e) {
      setEditError(e.message);
      captureSentryError(e, userData, "editVulnerabilityScan API in /Onboarding/VulnerabilityScan/util.js");
    }
  } else {
    setEditError("Empty input");
  }
  setEditLoading(false);
};
