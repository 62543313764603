import React, { useState, useContext, useEffect } from "react";
import MobileOnboarding from "./Mobile/MobileOnboarding";
import ContactsOnboarding from "./Contacts/ContactsOnboarding";
import CloudOnboarding from "./Cloud/CloudOnboarding";
import VulnerabilityScan from "./VulnerabilityScan/VulnerabilityScan";
import "../../styles/pages/Onboarding/Onboarding.scss";
import {isMobileMDRUser, isMobileOnly, hasAdminAccess, checkTenantType} from "../../util/userControl";
import UserData from "../../store/User/UserData";
import EndpointsOnboarding from "./Endpoints/EndpointsOnboarding";
import TenantsOnboarding from "./Tenants/TenantsOnboarding";
import { getTenants } from "../../api/ApiClient";
import { Loader } from "../../components/Loader/Loader";
import TenantDropdown from "../../navigation/TenantDropdown";
import TenantDropDownStyles from "../../styles/pages/Onboarding/TenantDropDownStyles";
import ReactGA from 'react-ga4';
import VirtualMachine from "./VirtualMachine";
import Firewall from "./Firewall";
import EditIcon from '@mui/icons-material/Edit';
import TenantDetailsModal from "./Tenants/TenantDetails.js/TenantDetailsModal";
import { captureSentryError } from "../../util/sentry";

const defaultSideMenu = ["tenants", "people", "endpoints", "integrations", "network", "vulnerability-scan", "virtual-machine", "mobile"];

const Onboarding = ({route}) => {
  const [userData] = useContext(UserData);
  const [currPage, setCurrPage] = useState();
  const [loading, setLoading] = useState();
  const [sideMenuOptions, setSideMenuOptions] = useState([]);
  const [childAndParentTenantList, setChildAndParentTenantList] = useState();
  const [selectedChildTenant, setSelectedChildTenant] = useState(null);
  const [hasParent, setHasParent] = useState(true);
  const isMobileMDR = isMobileMDRUser(userData);
  const mobileOnly = isMobileOnly(userData);
  const [showTenantModal, setShowTenantModal] = useState(false);
  const isParent = checkTenantType(userData, 'is_parent')
  const [defaultTenant, setDefaultTenant] = useState();

  const setPage = (page) => {
    setCurrPage(page);
    window.history.pushState(null, '', `/onboarding/${page}`)
   }

  useEffect(() => {
    let userOptions = defaultSideMenu;

    userOptions = !isMobileMDR ? userOptions.filter((opt) => opt !== "mobile") : userOptions;
    userOptions = mobileOnly ? userOptions.filter((opt) => opt !== "endpoints" && opt !== "integrations") : userOptions;
    userOptions = !childAndParentTenantList || hasParent ? userOptions.filter((opt) => opt !== "tenants") : userOptions;
    userOptions = isParent === true || userData.tenantConfig?.is_onboarding ? userOptions.filter((opt) => !["endpoints", "integrations", "network", "mobile", "virtual-machine", "vulnerability-scan"].includes(opt)) : userOptions
    setSideMenuOptions(userOptions);
    let defaultPage = route && userOptions.includes(route) ? route : userOptions[0];
    setPage(defaultPage);

    // eslint-disable-next-line
  }, [isMobileMDR, mobileOnly, childAndParentTenantList, hasParent, route]);

  useEffect(() => {
    async function getChildAndParentTenants() {
      try {
        const tenantItems = await getTenants();
        const tenantInfo = tenantItems?.find(
          (t) => t.id === userData?.tenant
        );

        let isAdmin = hasAdminAccess(userData?.role);
        const parent = tenantInfo.parent;
        setHasParent(parent);
        if(parent){
          let parentTenantInfo = tenantItems?.find(
            (t) => t.id === parent
          );
          isAdmin = hasAdminAccess(parentTenantInfo?.role);
        }
        
        let childrenAndParent = tenantItems.filter(
          (t) => hasAdminAccess(t.role) & (t.parent === userData.tenant || t.id === userData.tenant || (t.parent && t.parent === parent) || t.id === parent)
        );
        if (isAdmin && childrenAndParent.length > 1) {
          setChildAndParentTenantList(
            childrenAndParent
              .map((item) => ({
                label: item.name,
                value: item.id,
                id: item.id,
                name: item.id,
              }))
              .sort((a, b) =>
                a.label < b.label ? -1 : a.label > b.label ? 1 : 0
              )
          );
        }
      } catch (e) {
        captureSentryError(e, userData, 'getTenants API in onboarding.js');
      }
      setLoading(false);
    }
    setLoading(true);
    userData?.tenant && userData?.tenantConfig && getChildAndParentTenants();
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.tenantConfig]);

  const getDefaultTenant = () => {
    if (selectedChildTenant?.id){
      return {
        label: selectedChildTenant.name,
        value: selectedChildTenant.id,
        id: selectedChildTenant.id,
        name: selectedChildTenant.id,
      };
    }
    let tenant = localStorage.getItem('tenant') || userData?.tenant;
    let defaultTenant = userData.userTenants.filter(item => item.value === tenant)

    return {
      label: defaultTenant[0].label,
      value: tenant,
      id: tenant,
      name: tenant,
    }
  }

  useEffect(() => {
    if (selectedChildTenant || userData?.userTenants?.length) setDefaultTenant(getDefaultTenant());
    // eslint-disable-next-line
  }, [userData, selectedChildTenant]);

  const selectTenant = (selected) => {
    localStorage.setItem('tenant', selected);
    window.history.replaceState({}, document.title)
    ReactGA.gtag("set", "user_properties", {"Tenant": selected});
    window.location.reload();
  }

  const navigateToChildTenant = (tenant) => {
    setLoading(true);
    selectTenant(tenant.id);
  }

  const showParentDropdown = childAndParentTenantList && currPage !== "tenants";

  return (
    <div className={`onboarding-container ${currPage}`}>
      <div className={"header-row"}>
        <p className={"page-title"}>ONBOARDING</p>
      </div>
      <div className="tenant-modal-btn-container" onClick={() => setShowTenantModal(true)}>
        <span className={"tenant-info-text"}>Edit Tenant</span>
        <EditIcon className="tenant-modal-btn" fontSize="medium" onClick={() => setShowTenantModal(true)} />
      </div>
      {showTenantModal && (
        <TenantDetailsModal
          tenantId={userData?.tenant}
          closeFunction={() => setShowTenantModal(false)}
          refreshData={() => {}}
        />
      )}    
      {loading ? <div className="root-loader"><Loader /></div> :
        <>
          <div className="side-bar">
            <ul className="side-options">
              {sideMenuOptions.map((opt) => (
                <li
                  key={opt}
                  onClick={() => setPage(opt)}
                  className={`side-opt ${currPage === opt && "selected"}`}
                >{opt.split('-').join(" ")}
                </li>
              ))}
            </ul>
          </div>
          {showParentDropdown && (
            <TenantDropdown
              className={`child-tenants-dropdown ${currPage}`}
              data={childAndParentTenantList}
              styles={TenantDropDownStyles}
              onChange={selectTenant}
              defaultValue={defaultTenant}
            /> 
          )}
          <div className={`content-container ${currPage} ${showParentDropdown ? "lower" : ""}`}>
            {currPage === "mobile" && <MobileOnboarding />}
            {currPage === "endpoints" && <EndpointsOnboarding />}
            {currPage === "people" && (
              <ContactsOnboarding
                childAndParentTenantList={childAndParentTenantList}
                selectedChildTenant={selectedChildTenant}
                setSelectedChildTenant={setSelectedChildTenant}
              />
            )}
            {currPage === "integrations" && <CloudOnboarding />}
            {currPage === "network" && <Firewall />}
            {currPage === "tenants" && <TenantsOnboarding navigateToChildTenant={navigateToChildTenant}/>}
            {currPage === "vulnerability-scan" && <VulnerabilityScan setPage={setPage}/>}
            {currPage === "virtual-machine" && <VirtualMachine />}
          </div>
        </>
      }
    </div>
  );
};

export default Onboarding;
