import React, { useContext, useState } from "react";
import Modal from "../../components/Modal/Modal";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Button from "../../components/Button/Button";
import '../../styles/pages/Vulnerabilities2/Vulnerabilities/ActionModal.scss';
import UserData from "../../store/User/UserData";
import RadioSelection from "../Onboarding/Cloud/components/RadioSelection";
import { radioConfig } from "./config/vulnerabilitytabconfig";
import {Loader} from "../../components/Loader/Loader";

const ActionModal = ({
                       selectedGlobalAction,
                       className,
                       count,
                       setSelectedGlobalAction,
                       updateAssets,
                       selectedCheckboxes,
                       selectActionFromDropdown,
                       data,
                       vuln_id,
                       refreshData,
                       loadingActionUpdate,
                       setLoadingActionUpdate
                     }) => {
  const [userData] = useContext(UserData);
  const [radioSelected, setRadioSelected] = useState('Change all');

  const handleClose = () => setSelectedGlobalAction('Global Actions');

  const handleRadioSelection = (event, name) => setRadioSelected(name)

  return (
    <Modal close={handleClose} handleClose={handleClose} className={className}>
      <span className="modal-title">Change Risk Selection:
        <span className={"action-value"}>{selectedGlobalAction}</span>
      </span>
      <div className={"modal-values"}>
        <p className={"text"}>Would you like to change your risk selection to the {count} assets with this
          vulnerability, or keep individual risk selection per asset?</p>
        <RadioSelection
          options={radioConfig}
          handleChange={handleRadioSelection}
          selected={radioSelected}
          className={"radio"}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"} />}
        />
        {loadingActionUpdate ? <Loader/> :
          <Button
            className={"update-button comment"}
            disabled={false}
            text={'SAVE'}
            onClick={async () => {
              if (radioSelected === "Change all") {
                await updateAssets(selectedGlobalAction, 0, selectedCheckboxes, selectActionFromDropdown, vuln_id, data, userData, true, setLoadingActionUpdate);
                refreshData(true);
              }
              handleClose();
            }}
          />
        }
      </div>
    </Modal>
  );
};

export default ActionModal;
