import React, {useContext, useEffect, useState} from "react";
import '../../styles/components/Modal/Modal.scss';
import {Modal} from "../../components";
import {getConnectionsModalData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {abbreviateNumber, formatTime} from "../../util/format";
import Table from "../../components/Table/Table";
import NotFound from "../../navigation/NotFound";
import TableHeader from "../../components/Table/TableHeader";
import Row from "../../components/Table/Row";
import '../../styles/pages/Connections/ConnectionsModal.scss';
import PaginationControls from "../../components/Table/PaginationControls";
import { tableHeaderConfig } from "./config/connectionsMap";
import { captureSentryError } from "../../util/sentry";
import Tooltip from "../../components/Tooltip/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const tooltip = (
  <ul className="main-list">
    <li>
      <div className={"circle green"} />
      ActZero is successfully ingesting data
      <ul className="sub-list">
        <li>
          Supported Log Format (list of supported{" "}
          <a
            href="https://docs.actzero.ai/log-forwarding/"
            target="_blank"
            rel="noreferrer"
          >
            parsers
          </a>
          )
        </li>
        <li>IP/Device matches with the onboarding page</li>
      </ul>
    </li>

    <li>
      <span className={"circle yellow"} />
      ActZero is successfully ingesting data and could be one of the conditions
      below
      <ul className="sub-list">
        <li>
          Unsupported Log Format (list of supported{" "}
          <a
            href="https://docs.actzero.ai/log-forwarding/"
            target="_blank"
            rel="noreferrer"
          >
            parsers
          </a>
          )
        </li>
        <li>IP/Device is not specified in the onboarding page</li>
      </ul>
    </li>

    <li>
      <span className={"circle red"} /> ActZero is unable to ingest data and
      could be a combination of below conditions
      <ul className="sub-list">
        <li>No data or no recent data in the past 12 hours</li>
        <li>
          Unsupported Log Format (list of supported{" "}
          <a
            href="https://docs.actzero.ai/log-forwarding/"
            target="_blank"
            rel="noreferrer"
          >
            parsers
          </a>
          )
        </li>
        <li>Not onboarded</li>
      </ul>
    </li>
  </ul>
);

const ConnectionsModal = ({close, title}) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [userData] = useContext(UserData);
    const [sortFilters, setSortFilters] = useState([])
    const [page, setPage] = useState(1)
    const [fetchError, setFetchError] = useState(false)
    const [modalParams, setModalParams] = useState({page: 1, size: 50})

    const handleChangePage = async (currPage) => {
      setPage(currPage)
      setModalParams({...modalParams, page: currPage})
    };

    useEffect(() => {
      const getConnections = async () => {
        setFetchError(false);
        setLoading(true)
        try {
          let response = await getConnectionsModalData(userData?.tenant, {type: 'firewall', ...modalParams})
          setData({results: response?.results?.firewall, total: response?.total})
        } catch (e) {
          captureSentryError(e, userData, "getConnectionsModalData API in ConnectionsModal.js");
          setFetchError(true);
        } finally {
          setLoading(false)
        }
      }
      getConnections()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.tenant, userData?.userType, modalParams]);

    return (
      <Modal close={close} className={"connections-list-modal"}>
        <p className={"modal-title connections-list"}>{title}</p>
        <div className="modal-tooltip">
        <Tooltip content={tooltip} direction="bottom" disableTimeout>
          <InfoOutlinedIcon className="info-icon" />
        </Tooltip>
      </div>
        {!loading &&
        <div className="page-container">
          <PaginationControls
            displayData={data}
            page={page}
            setPage={handleChangePage}
            toggleShowDropdown={() => {
            }}
            setParams={setModalParams}
            params={modalParams}
            showSortDropdown={false}
          />
        </div>
        }
        {!loading ?
          fetchError ? <NotFound isError dataError className="panel modal" /> 
          :
          <Table page={"connections-list-table"}>
            <TableHeader
              mainRow={tableHeaderConfig}
              setSortFilters={setSortFilters}
              sortFilters={sortFilters}
              setParams={setModalParams}
              params={modalParams}
            />
            {data?.total > 0 && data?.results?.map((item, i) =>
              <Row className={"list-row"} key={i}>
                <td className={"list-item ip-address"}>{item.ip_address}</td>
                <td className={"list-item status"}><span className={"circle " + (item.status)}/></td>
                <td className={"list-item label"}>{item.label ? item.label : "Unknown"}</td>
                <td className={"list-item last-seen"}>{formatTime(item.last_seen)}</td>
                <td className={"list-item total-events"}>{abbreviateNumber(item.total_events)}</td>
                <td className={"list-item comment"}>{item.comment}</td>
              </Row>
            )}
          </Table> : <Loader/>}
      </Modal>
    );
  }
;

export default ConnectionsModal;
