import React, { useState, useEffect } from "react";
import "../../styles/pages/Preferences/App2FA.scss";
import { verifyTOTPSetup, updateMFAPreference } from "aws-amplify/auth";
import QRCodeCanvas from "qrcode.react";
import Grid from "@mui/material/Grid";
import { Loader } from "../../components/Loader/Loader";
import CheckIcon from "@mui/icons-material/Check";
import { applicationIcons } from "../Login/loginConfig";
import ErrorIcon from "@mui/icons-material/Error";
import { setAuthRegistration } from "../../util/setAuthRegistration";

const App2FA = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [appError, setAppError] = useState("");
  const [qrLoading, setQrLoading] = useState(true);
  const [qrError, setQrError] = useState(false);
  const [qrCodeURL, setQrCodeURL] = useState("");
  const [totpToken, setTotpToken] = useState("");
  const [appSuccess, setAppSuccess] = useState(false);
  const [showManualQRcode, setShowManualQRcode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [accountName, setAccountName] = useState("");

  const handleApp2FASetup = async () => {
    setSubmitLoading(true);
    setAppError("");
    try {
      await verifyTOTPSetup({
        code: verificationCode,
      });
      await updateMFAPreference({ totp: "PREFERRED" });
      setShowManualQRcode(false);
      setAppSuccess(true);
      setQrCodeURL("");
    } catch (e) {
      setAppError("An error has occurred");
    }
    setSubmitLoading(false);
  };

  const handleManualQRCode = () => {
    setShowManualQRcode(!showManualQRcode);
  };

  useEffect(() => {
    setAuthRegistration({
      setAccountName,
      setTotpToken,
      setQrCodeURL,
      setQrLoading,
      setQrError,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={"MFA-settings-container"}>
      <div className="header">Two-Factor Authentication</div>
      <div className="App-2FA-container">
        {!appSuccess && (
          <>
            <div className="confirmed-setup-msg">
              {"You're registered "}
              <CheckIcon
                className="checkmark"
                fontSize="small"
                sx={{ color: "#D0F31A" }}
              />
              <div>
                {
                  " Re-registering will make your previous QR code and existing two-factor setup obsolete."
                }
              </div>
            </div>

            <div className="subheader">
              <b>To set up two factor authentication</b>, download one of these
              supported applications onto your mobile device:
            </div>
            <div className={"totp-images"}>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                {applicationIcons.map((icon) => (
                  <Grid item key={icon.className}>
                    <img
                      className={`icon ${icon.className}`}
                      src={icon.img}
                      alt={icon.label}
                      width={icon.width}
                    />
                    <p className={`label ${icon.className}`}>{icon.label}</p>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
        {appSuccess ? (
          <div className="success-msg">
            <CheckIcon fontSize="large" sx={{ color: "#D0F31A" }} />
            <span className="text">App 2FA was reconfigured successfully.</span>{" "}
          </div>
        ) : (
          <>
            <div className="qr-container">
              <div className="description-text">
                <b> Once you have it installed, </b> use the app to scan in this
                QR code or enter key manually. Then enter the resulting code in
                the box below.
              </div>
              <div className="the-code">
                {qrLoading ? (
                  <Loader />
                ) : qrError ? (
                  <span className="qr-error">{"Error generating QR code"}</span>
                ) : (
                  <QRCodeCanvas value={qrCodeURL} size={128} includeMargin />
                )}
              </div>
            </div>
            <div className="manual-qr-code">
              <div>
                <button
                  className="manual-qr-code reveal-btn"
                  onClick={() => handleManualQRCode()}
                >
                  {"Setup key manually »"}
                </button>
                {showManualQRcode && (
                  <div className="manual-qr-code">
                    {" "}
                    <div className="account-name">
                      Account Name: {accountName}{" "}
                    </div>{" "}
                    <div className="key">Key: {totpToken} </div>
                  </div>
                )}
              </div>
            </div>

            <div className="verification-code-container">
              <div className="input-label">Verification code </div>
              <input
                type="text"
                value={verificationCode}
                onKeyDown={(e) => e.key === "Enter" && handleApp2FASetup()}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Numeric code"
              />
              <div className="confirm-btn-container">
                <button
                  className="confirm-btn"
                  onClick={() => handleApp2FASetup()}
                >
                  {submitLoading ? <Loader /> : "Confirm Code"}
                </button>
                {appError && (
                  <div className="error-msg app">
                    <ErrorIcon className="err-icon" /> {appError}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App2FA;
