import React, {useContext, useEffect, useState} from "react";
import '../../../styles/pages/Vulnerabilities2/Vulnerabilities/ExpandedView.scss';
import Tab from "../../../components/Tab/Tab";
import {actionOptions, tableHeaders, tables, tabs, updateAsset} from "../config/vulnerabilitytabconfig";
import {Pagination} from "../../../components";
import {getVulnerabilitiesExpandedData} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";
import UserData from "../../../store/User/UserData";
import {Loader} from "../../../components/Loader/Loader";
import {ExpandedViewTable} from "./ExpandedViewTable";
import Dropdown from "../../../components/Dropdown/Dropdown";
import ActionModal from "../ActionModal";
import {ExpandedTableList} from "../ExpandedTable";

const ExpandedView = ({data, index, setParentTab, action}) => {
    const [userData,] = useContext(UserData);
    const [currTab, setCurrTab] = useState('affected-assets');
    const [page, setPage] = useState(1);
    const [loading, setIsLoading] = useState(true)
    const [tableData, setTableData] = useState({})
    const [loadingTable, setLoadingTable] = useState(false)
    const [selectedGlobalAction, setSelectedGlobalAction] = useState('Global Actions')
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectAllCheckboxes, setSelectAllCheckboxes] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([false]);
    const [loadingActionUpdate, setLoadingActionUpdate] = useState(false);
    const [params, setParams] = useState({page: page, size: 25, sort_by: 'ipaddress', order: 'desc'})

    const selectActionFromDropdown = (value) => {
      setSelectedGlobalAction(value);
      setOpenDropdown(false);
    };

    const getData = async (onPageChange) => {
      setIsLoading(onPageChange !== true);
      if (onPageChange === true) {
        setLoadingTable(true);
      }
      try {
        let newParams = {...params, page: page}
        if (action) newParams.action = action
        if (newParams.sort_by === 'action_taken') newParams.sort_by = 'action'
        let response = await getVulnerabilitiesExpandedData(userData.tenant, data.vulnerability_id, newParams);
        setTableData(response)
      } catch (e) {
        captureSentryError(e, userData, 'getVulnerabilitiesTable')
      } finally {
        setIsLoading(false)
        setLoadingTable(false)
        setSelectedCheckboxes([false])
        setSelectAllCheckboxes(false)
      }
    }

    useEffect(() => {
      (userData.tenant && loading === true) ? getData() : getData(true)
      // eslint-disable-next-line
    }, [userData.tenant, page, params]);

    const sortTable = (value) => {
      const newParams = {
        ...params,
        sort_by: value,
        order: params.sort_by === value && params.order === 'asc' ? 'desc' : 'asc',
      };
      setParams(newParams);
    };

    return (
      <div className={"flex expanded-container"} key={index}>
        {loading ? <Loader/> :
          <>
            <div className={"vulnerability-data-table"}>
              {tables.map((table, index) =>
                <ExpandedTableList key={index} data={tableData?.details} labels={table?.labels} title={table?.title}/>
              )}
            </div>
            {selectedGlobalAction !== 'Global Actions' &&
            <ActionModal
              data={tableData?.affected_assets?.results}
              vuln_id={data?.vulnerability_id || ''}
              updateAssets={updateAsset}
              selectedCheckboxes={selectedCheckboxes}
              refreshData={getData}
              selectActionFromDropdown={selectActionFromDropdown}
              setSelectedGlobalAction={setSelectedGlobalAction}
              selectedGlobalAction={selectedGlobalAction}
              className={"update-action-modal"}
              setLoadingActionUpdate={setLoadingActionUpdate}
              loadingActionUpdate={loadingActionUpdate}
            />}
            <div className={"assets-and-details"}>
              <div className={"tab-container"}>
                {tabs.map((tab) => (
                  <Tab
                    onClick={() => setCurrTab(tab.configName)}
                    key={tab.configName}
                    data={tab}
                    selected={tab.configName === currTab}
                  />
                ))}
              </div>
              <div className={"table-container"}>
                {loadingTable ? <Loader/> : <>
                  <div className={"flex assets-pagination"}>
                    <p className={"results"}>{tableData?.affected_assets?.total.toString() + ' Results'}</p>
                    {tableData?.affected_assets?.total > 25 && <Pagination
                      setPage={setPage}
                      page={page}
                      total={tableData?.affected_assets?.total}
                      pageSize={25}
                    />}
                    <Dropdown
                      className="global-action-dropdown"
                      name={"Global Actions"}
                      dropdownList={actionOptions}
                      selectItem={(value) => selectActionFromDropdown(value)}
                      selectedItem={selectedGlobalAction}
                      showDropdown={openDropdown === true}
                      setShowDropdown={setOpenDropdown}
                    />
                  </div>
                  <ExpandedViewTable
                    actionOptions={actionOptions}
                    page={"vulnerabilities"}
                    className={"vulnerabilities"}
                    headers={tableHeaders}
                    data={tableData?.affected_assets?.results}
                    vuln_id={data?.vulnerability_id || ''}
                    selectAllCheckboxes={selectAllCheckboxes}
                    setSelectAllCheckboxes={setSelectAllCheckboxes}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    setParentTab={setParentTab}
                    sortTable={sortTable}
                    params={params}
                  />
                  {tableData?.affected_assets?.total > 25 && <Pagination
                    setPage={setPage}
                    page={page}
                    total={tableData?.affected_assets?.total}
                    pageSize={25}
                  />}</>}
              </div>
            </div>
          </>}
      </div>
    );
  }
;

export default ExpandedView;
