import * as React from 'react';
import '../../styles/components/Table/TableHeader.scss';
import {sorting} from '../../config/mappingData';
import Tooltip from "../../components/Tooltip/Tooltip";
import Checkbox from "@mui/material/Checkbox";

const TableHeader = ({
                       mainRow,
                       className,
                       setSortFilters,
                       sortFilters,
                       setParams,
                       params,
                       tooltipField = null,
                       tooltipValue = null,
                       selectCheckbox,
                       checked,
                       sortFrontEnd = false,
                       sortBy='',
                       setSortBy={},
                       sortOrder='asc',
                       setSortOrder={}
                     }) => {

  const handleSortResults = (field) => {
    const filterValue = sortFilters[field]
    params['sort_by'] = field.toLowerCase().split(' ').join('_')
    if (filterValue === false) {
      params['order'] = 'desc'
      sortFilters[field] = true
    } else {
      params['order'] = 'asc'
      sortFilters[field] = false
    }
    setParams({...params})
    setSortFilters({...sortFilters})
  }

  const toggleSortOrder = () => sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  const handleSortResultsFront = (field) => {
    params['sort_by'] = field.toLowerCase().split(' ').join('_')
    sortBy === field ? toggleSortOrder() : setSortBy(field)
    sortOrder === "asc" ? sortFilters[field] = false : sortFilters[field] = true
    setSortFilters({...sortFilters})
  }

  return (
    <tr key={className} className={"table-header-container " + className}>
      {Object.values(mainRow)?.length > 0 && Object.values(mainRow).map((item, index) => {
        return (
          item.sort ?
            <th key={index} className={((item.field === 'Exploit?') ? 'Exploit' : item.field) + " td-value"}>
              {item.checkbox === true &&
              <Checkbox
                key={index}
                className="custom-checkbox"
                checked={checked}
                onChange={() => selectCheckbox(item)}
              />}
              {tooltipField && tooltipField === item.field.toLowerCase() ?
                <Tooltip content={tooltipValue} className={"title-tooltip"} direction={"bottom"} type={"action"}>
                  <button
                    className={item.field + ' sort' + (params['sort_by'] === (item.field).toLowerCase() ? ' true' : ' false')}
                    onClick={() => sortFrontEnd? handleSortResultsFront(item.field) : handleSortResults(item.field)}>
                    {item.header}
                  </button>
                </Tooltip> :
                <button
                  className={item.field + ' sort' + (params['sort_by'] === (item.field).toLowerCase() ? ' true' : ' false')}
                  onClick={() => sortFrontEnd? handleSortResultsFront(item.field) : handleSortResults(item.field)}>
                  {item.header}
                </button>}
              {params['sort_by']  === (item.field).toLowerCase().split(' ').join('_') ? sortFilters[item.field] === false ?
                <img className={"asc " + item.field} src={sorting['Asc']} alt=""/> :
                <img className={"desc " + item.field} src={sorting['Desc']} alt=""/> : null}
            </th>
            :
            <th key={index} className={((item.field === 'Exploit?') ? 'Exploit' : item.field) + " td-value"}>
              <div className={(item.field === 'Exploit?') ? 'Exploit' : item.field}>
                {item.header}
              </div>
            </th>
        )
      })}
    </tr>
  );
}

export default TableHeader;
