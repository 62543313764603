import React, { useContext, useEffect, useState } from "react";
import {
  getCurrentUser,
  signIn,
  confirmSignIn,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";
import {
  Authenticator,
  useAuthenticator,
  translations,
} from "@aws-amplify/ui-react";
import { formFields, headers, errors, i18n, components } from "./loginConfig";
import "../../styles/pages/Login/Login.scss";
import Router from "../../navigation/Router";
import UserData from "../../store/User/UserData";
import { Cache } from "aws-amplify/utils";
import ErrorIcon from "@mui/icons-material/Error";
import { Loader } from "../../components/Loader/Loader";
import GlobalState from "../../store/GlobalState/GlobalState";
import App2FA from "./App2FA";
import { setUserSession } from "./util";
I18n.putVocabularies(translations);
I18n.setLanguage("en-US");
I18n.putVocabulariesForLanguage("en", i18n);

const Login = () => {
  const [, setUserData] = useContext(UserData);
  const [, setAzState] = useContext(GlobalState);
  const { route } = useAuthenticator((context) => [context.route]);
  const { toForgotPassword, toSignIn } = useAuthenticator();
  const [error, setError] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [setupMFA, setSetupMFA] = useState("pending");

  useEffect(() => {
    if (!localStorage.getItem("hasRefreshed")) {
      localStorage.setItem("hasRefreshed", true);
      setTimeout(() => window.location.reload(), 100); // delay refresh for 1Password to maintain input focus
    }
    return () => localStorage.removeItem("hasRefreshed");
  }, []);

  useEffect(() => {
    const setup = async () => {
      setLoadingUserData(true);
      setError();
      await setUserSession({setSetupMFA, setAzState, setUserData});
      setLoadingUserData(false);
    };
    if (route === "authenticated") setup();
    else {
      setLoadingUserData(false);
    }
    // eslint-disable-next-line
  }, [route, setupMFA]);

  const services = {
    async handleSignIn(formData) {
      Cache.clear(); // clear any previous user session
      setSetupMFA(true); // ensure MFA setup is required

      try {
        const response = await signIn(formData);
        if (
          response.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
        ) {
          setSetupMFA(false);
        }
        return response;
      } catch (e) {
        formFields.setupTOTP.QR.totpUsername = "";
        e.name === "UserNotFoundException" ||
        e.name === "NotAuthorizedException"
          ? setError(errors.userNotFound)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },

    async handleConfirmSignIn(formData) {
      Cache.clear(); // clear any previous user session
      try {
        const response = await confirmSignIn(formData);
        return response;
      } catch (e) {
        e.name === "CodeMismatchException"
          ? setError(errors.invalidCode)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },

    async handleForgotPassword({ username, clientMetadata }) {
      try {
        const response = await resetPassword({
          username,
          options: {
            clientMetadata,
          },
        });
        return response;
      } catch (e) {
        e.name === "UserNotFoundException"
          ? setError(errors.userNotFound)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },

    async handleForgotPasswordSubmit(formData) {
      const { username, newPassword, confirmationCode, clientMetadata } =
        formData;
      try {
        const response = await confirmResetPassword({
          username,
          newPassword,
          confirmationCode,
          options: {
            clientMetadata,
          },
        });
        return response;
      } catch (e) {
        e.name === "InvalidParameterException"
          ? setError(errors.invalidPasswordFormat)
          : setError(e.message);
        return getCurrentUser(); // allow another attempt
      }
    },
  };
  
  return loadingUserData || route === "idle" || !route ? (
    <div className="root-loader">
      <Loader />
    </div>
  ) : route === "authenticated" && !setupMFA ? (
    <>
    <Router />
    <Authenticator/>
    </>
  ) : (
    <div className={`login-container ${route === "setup" ? "hidden" : ""}`}>
      {route !== "setup" && (
        <div
          className="bg-actzero"
          style={{ backgroundImage: `url('/mountain-scene.jpg')` }}
        >
          <div className={"breach-info"}>
            <p className={"breach-number"}>
              <span>BREACH?</span> 1-855-917-4981
            </p>
            <p
              className={"pointer breach-email"}
              onClick={() =>
                (window.location = "mailto:threathunting@actzero.ai")
              }
            >
              threathunting@actzero.ai
            </p>
          </div>
          <div className="mask-bg" />
        </div>
      )}
      <div className={"authenticator-container"}>
        {route !== "setup" && (
          <div className="logo">
            <img src="/ActZero-Logo_rev.svg" alt="ActZero" />
          </div>
        )}
        {route === "authenticated" && setupMFA && setupMFA !== "pending" ? (
          <div>
            <App2FA setSetupMFA={setSetupMFA} />
          </div>
        ) : (
          <>
            {route !== "setup" && (
              <>
                <div className={"header " + route}>{headers[route]}</div>
                <div className="subheader">
                  {route === "signIn" &&
                    "Please enter your credentials to proceed:"}
                </div>
              </>
            )}
            {error && (
              <div className="error-msg">
                <ErrorIcon className="err-icon" /> {error}
              </div>
            )}
            <Authenticator
              formFields={formFields}
              hideSignUp={true}
              submitButtonText="Login"
              components={components}
              className={route}
              services={services}
            />
            {route === "signIn" ? (
              <div className="reset-container">
                {"Forgot your password? "}
                <span className="link" onClick={toForgotPassword}>
                  Reset password
                </span>
              </div>
            ) : route !== "setup" ? (
              <div className="return-btn" onClick={toSignIn}>
                Back to Sign In
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
