import ReactGA from "react-ga4";
import { fetchAuthSession, fetchMFAPreference } from "aws-amplify/auth";
import { getTenants } from "../../api/ApiClient";
import { isRoot } from "../../util/userControl";
import { captureSentryError } from "../../util/sentry";
import { getFeatureFlagAccess } from "../../util/getFeatureFlag";

const defaultRole = "Read_Only";

const getTenantList = async (userData) => {
  try {
    let tenantItems = await getTenants();
    return formatTenants(tenantItems);
  } catch (aggregateError) {
    captureSentryError(aggregateError, userData, "getTenants in Login.js");
  }
};

const formatTenants = (tenantItems) => {
  if (tenantItems && tenantItems.length > 0) {
    tenantItems = tenantItems.map((item) => ({
      label: item.name,
      value: item.id,
      id: item.id,
      name: item.id,
      role: item.role,
      is_onboarding: item.is_onboarding,
    }));
    tenantItems = tenantItems.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );
    tenantItems = tenantItems.sort((a, b) =>
      a.is_onboarding === b.is_onboarding ? 0 : !a.is_onboarding ? -1 : 1
    );
    return tenantItems;
  }
};

const setFeatureFlag = async ({ updateUserData, setAzState }) => {
  try {
    await getFeatureFlagAccess(
      setAzState,
      updateUserData,
      "has-new-vuln-access",
      "hasNewVulnAccess"
    );
  } catch (e) {
    captureSentryError(e, updateUserData, "setFeatureFlag in Login.js");
  }
};

export const setUserSession = async ({
  setSetupMFA,
  setAzState,
  setUserData,
}) => {
  try {
    const mfaInfo = await fetchMFAPreference();
    if (mfaInfo.preferred === "TOTP" && mfaInfo.enabled) {
      const session = await fetchAuthSession();
      setSetupMFA(false);
      const idToken = session?.tokens?.idToken;
      const { sub: userId, email } = idToken.payload;
      const userTenants = await getTenantList({ userId, email });
      let selectedTenant =
        localStorage.getItem("tenant") || userTenants[0].value;
      let selectedTenantRole = defaultRole;
      let foundTenant = userTenants.filter(
        (item) => item.value === selectedTenant
      );
      // If the tenant in localStorage isn't in the user's tenants use the first tenant in the list
      if (foundTenant.length === 0) {
        selectedTenant = userTenants[0].value;
        selectedTenantRole = userTenants[0].role;
      } else {
        selectedTenantRole = foundTenant[0].role;
      }
      const isRootUser = isRoot(selectedTenantRole);
      let userType = isRootUser ? "root" : "tenant";
      let tenant = null;
      let gaTenant = null;
      if (selectedTenant) {
        tenant = selectedTenant;
        gaTenant = selectedTenant;
      }
      const updateUserData = {
        tenant,
        userType,
        userId,
        email,
        role: selectedTenantRole,
        userTenants: userTenants,
      };
      setUserData((state) => ({
        ...state,
        ...updateUserData,
      }));
      localStorage.setItem("tenant", tenant);
      ReactGA.gtag("set", "user_properties", { Tenant: gaTenant });
      await setFeatureFlag({ updateUserData, setAzState });
    } else {
      setSetupMFA(true);
    }
  } catch (e) {
    setUserData((state) => ({
      ...state,
      tenant: "",
      userType: "",
      userId: "",
      email: "",
      role: "",
      userTenants: [],
    }));
  }
};
