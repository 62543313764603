import React, {useEffect, useState} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import GenericCheckbox from "../../components/Dropdown/GenericCheckbox";
import SearchIcon from '@mui/icons-material/Search';
import TooltipIcon from "../../assets/icons/tooltip.svg";
import {DateDropdown, GenericRadio} from "../../components";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import moment from 'moment';
import '../../styles/components/Filters/PageFilters.scss';
import '../../styles/components/Dropdown/GenericCheckbox.scss';
import {useLocation} from "react-router-dom";
import Tooltip from "../../components/Tooltip/Tooltip";

const PageFilters = ({
                       className,
                       filterList,
                       setOpenFilters,
                       setParams,
                       handleClose,
                       initialSearch,
                       timeConfig,
                       setMetricParams = null,
                       defaultDate = null,
                       collapsible = false,
                       labelStyle,
                       newVulnerabilities = null,
                       selectedDate,
                       setSelectedDate,
                       isReset,
                       type,
                       searchParam = false,
                       setSearchTerm = {},
                     }) => {
  const [startDate, handleStartDateChange] = useState({
    time: defaultDate?.time ? defaultDate?.time : defaultDate?.time === '' ? '' : new Date().setDate(new Date().getDate() - 30),
    // when text is '' the time filter is using the Date modal instead of quick time filters (i.e. Last Hour)
    text: defaultDate?.text ? defaultDate?.text : ''
  });
  const [endDate, handleEndDateChange] = useState({
    time: defaultDate?.time === '' ? '' : new Date(),
    text: defaultDate?.text ? defaultDate?.text : ''
  })
  const [query, setQuery] = useState('')
  const location = useLocation()
  const [togglePageFilters, setTogglePageFilters] = useState(false)
  const [invalidDateFormat, setInvalidDateFormat] = useState(false)

  useEffect(() => {
    isReset && handleStartDateChange(defaultDate)
    isReset && handleEndDateChange({
      time: defaultDate?.time === '' ? '' : new Date(),
      text: defaultDate?.text ? defaultDate?.text : ''
    })
    isReset && setQuery('')
  
    if (type?.includes('maturity-page') && isReset) {
      //maturity page should show all history by default
      handleEndDateChange({time: '', text: ''})
    }
  }, [defaultDate, isReset, type])

  useEffect(() => {
    let startTime, endTime
    if (invalidDateFormat) {
      return
    }
    if (startDate.text === '') {
      startTime = moment(startDate.time).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString(true)
      endTime = moment(endDate.time).set({hour: 23, minute: 59, second: 59, millisecond: 999}).toISOString(true)
    } else if (typeof startDate.time === 'string') { // Text based time filters such as current | previous
      startTime = startDate.time
      endTime = startDate.time
    } else {
      startTime = moment(startDate.time).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString(true) // setting ISOString(timeOffset) to true to keep local tz
      endTime = moment(endDate.time).set({hour: 23, minute: 59, second: 59, millisecond: 999}).toISOString(true)
    }
    if (startDate.text === 'default') {
      setParams(prevState => {
        delete prevState.start_time;
        delete prevState.end_time;
        return {...prevState, start_time: startTime, end_time: endTime}
      })
      if (setMetricParams) setMetricParams(prevState => {
        delete prevState.start_time;
        delete prevState.end_time;
        return {...prevState, start_time: startTime, end_time: endTime}
      })
    } else if (defaultDate?.text === 'none') {
      return;
    } else {
      setParams(prevState => {
        return {...prevState, start_time: startTime, end_time: endTime}
      })
      if (setMetricParams) setMetricParams(prevState => {
        return {...prevState, start_time: startTime, end_time: endTime}
      })
    }
  }, [startDate, endDate, setParams, setMetricParams, invalidDateFormat, defaultDate?.text])

  useEffect(() => {
    if (initialSearch) {
      setQuery(initialSearch)
    }
  }, [initialSearch, setQuery])

  const selectCheckbox = (type, checkboxName) => {
    filterList.map(filter => {
      let name = Object.keys(filter)[0]
      if (name === type) {
        if (name === 'Status' && className === 'hosts-filters endpoints') {
          // Put one year time filters when status filter is selected on endpoints page
          let now = new Date()
          let comparedTime = new Date(moment(now).startOf('day').subtract(365,'days').valueOf())
          handleStartDateChange({time: comparedTime, text: ''})
          handleEndDateChange({time: now, text: ''})
          setSelectedDate('')
          setParams(prevState => {
            delete prevState.start_time;
            delete prevState.end_time;
            return {...prevState}
          })
        }
        // When the Cloud source is unselect, then all the filters in the Cloud subsource has to be unselected
        const cloudSource = 'Cloud'
        if (checkboxName === cloudSource && filter[type].filters[checkboxName] && Object.keys(filterList[2])[0] === cloudSource) {
          Object.keys(filterList[2]['Cloud'].filters).forEach((item) => {
            filterList[2]['Cloud'].filters[item] = false
          })
        }
        filter[type].filters[checkboxName] = !filter[type].filters[checkboxName]
      }
      return filter
    })
    setOpenFilters([...filterList])
  }

  const handleChange = (e) => {
    const q = e.target.value || e.target.innerText;
    if (q === '') {
      setParams(prevState => {
        delete prevState.q;
        location.state = {}
        initialSearch = ''
        return {...prevState}
      })
    } else if (q.length >= 2) {
      setParams(prevState => {
        return {...prevState, q}
      })
    }
    handleClose()
    setQuery(q)
    searchParam && setSearchTerm(q)
  }
  const handleNewVulnerabilitiesChange = () => {
    filterList.map(filter => {
      let name = Object.keys(filter)[0]
      if (name === 'New_Vulnerabilities') {
        Object.keys(filter[name].filters).forEach((item) => {
          filter[name].filters[item] = false
        })
      }
      return filter
    })
    setOpenFilters([...filterList])
  }

  const handleRadioChange = (event, type, checkboxName) => {
    filterList.map(filter => {
      let name = Object.keys(filter)[0]
      if (name === type) {
        let filterObj = filter[type].filters
        Object.keys(filterObj).map(filterValue => {
          if (filterValue === checkboxName) {
            filter[type].filters[checkboxName] = !filter[type].filters[checkboxName]
          } else if (filterObj[filterValue] === true) {
            filter[type].filters[filterValue] = !filter[type].filters[filterValue]
          }
          return filterValue
        })
      }
      return filter
    })
    defaultDate = null
    setOpenFilters([...filterList])
  }

  const getFilterWidth = () => {
    if (filterList.length === 1) {
      return ' reduce-width'
    } else if (filterList.length === 2) {
      return ' medium-width'
    } else if (filterList.length >= 3) {
      return ' increase-width'
    } else {
      return ''
    }
  }

  return (
    <div className={(className ? className : '') + ` page-filters-container ${togglePageFilters}`}
         onClick={() => setTogglePageFilters(false)}>
      {collapsible && <span className={`expand-btn ${togglePageFilters}`} onClick={(e) => {
        e.stopPropagation();
        setTogglePageFilters(!togglePageFilters)
      }}>{togglePageFilters ? '+' : '-'}</span>}
      {className !== 'maturity-assessment' &&
      <FormControl sx={{m: 1, width: '30ch', top: 8, left: 40}} variant="outlined" id="form-search-bar" size="small">
        {!query && <InputLabel className={"input-label"} htmlFor="outlined-adornment-search">Search</InputLabel>}
        <OutlinedInput
          id="outlined-adornment-search"
          onChange={(e) => handleChange(e)}
          endAdornment={
            <InputAdornment position="end" id="search-field"><SearchIcon id="search-field"/></InputAdornment>
          }
          label="Search"
          value={query}
          disabled={togglePageFilters}
        />
      </FormControl>}
      <div className={"page-filters" + getFilterWidth()}>
        {filterList.length > 0 && filterList.filter(filter => ((Object.keys(filter)[0] !== 'Cloud') || (filterList[1]?.['Source']?.['filters']?.['Cloud']))).map((filter, index) => {
          let name = Object.keys(filter)[0]
          return <div key={index} className={`filter-item ${togglePageFilters} ${name}`}>
            <div className={(className ? className : '') + " dropdown-checkbox-container " + name}>
              {filter[name].tooltip ?
                <Tooltip
                  content={filter[name].tooltip}
                  direction="right"
                  className={"filter-tooltip"}
                >
                  <span className={"dropdown-checkbox-title " + name}>
                    {filter[name].title ? filter[name].title.toUpperCase() : name.toUpperCase()}
                    <img
                      className="tooltip-icon"
                      src={TooltipIcon}
                      alt="Instructions"
                    />
                  </span>
                </Tooltip>
                : <span className={"dropdown-checkbox-title " + name}>
                  {filter[name].title ? filter[name].title.toUpperCase() : name.toUpperCase()}
                </span>}
              {filter[name].radioConfig ?
                <GenericRadio
                  handleView={handleRadioChange}
                  className={"dropdown-checkbox generic-type customize-container-radio"}
                  handleChange={handleRadioChange}
                  name={name}
                  options={filter[name].radioConfig}
                  filters={filter[name].filters}
                  icon={<RadioButtonUncheckedIcon/>}
                  checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"}/>}/> :
                <GenericCheckbox
                  isOpen={filter[name].isOpen}
                  dropdownList={filter[name].filters}
                  selectCheckbox={selectCheckbox}
                  name={name}
                  data={filter}
                  labelStyle={labelStyle}
                />}
            </div>
          </div>
        })}
        <DateDropdown
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          endDate={endDate.time}
          startDate={startDate.time}
          timeConfig={timeConfig ? timeConfig : null}
          type={type ? `page-filter ${type}` : "page-filter"}
          defaultDate={defaultDate ? defaultDate : null}
          title={className === "hosts-filters" ? "LAST SEEN" : "TIME"}
          togglePageFilters={togglePageFilters}
          invalidDateFormat={invalidDateFormat}
          setInvalidDateFormat={setInvalidDateFormat}
          newVulnerabilities={newVulnerabilities}
          handleNewVulnerabilitiesChange={newVulnerabilities ? handleNewVulnerabilitiesChange : null}
        />
      </div>
    </div>
  )
}

export default PageFilters;
