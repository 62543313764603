import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import UserData from "../store/User/UserData";
import navigationConfig, {parentNavigationConfig} from "./config/navigationconfig";
import helpConfig from "./config/helpconfig";
import ReactGA from 'react-ga4';
import {getAnnouncements} from "../api/ApiClient";
import TenantDropdown from "./TenantDropdown";
import tenantDropdownStyles from "./config/tenantdropdownstyles";
import {AnnouncementBtn, AnnouncementModal} from "./Announcements";
import {checkTenantType, hasAdminAccess, showLogSearch} from "../util/userControl";
import {trackClick} from "../util/analytics";
import ToolsMenu from "./ToolsMenu";
import DropdownNav from "./DropdownNav"
import GlobalState from "../store/GlobalState/GlobalState";
import {captureSentryError} from "../util/sentry";
import { handleSignOut } from "../util/handleSignOut";

const Nav = () => {
  const [activeNavItem, setActiveNavItem] = useState("DASHBOARD");
  const [activeDropdown, setActiveDropdown] = useState("");
  const [indent] = useState(45);
  const [userData, setUserData] = useContext(UserData);
  const [tenantList, setTenantList] = useState([]);
  const [announcementList, setAnnouncementList] = useState([]);
  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [defaultTenant, setDefaultTenant] = useState();
  const isOnboarding = checkTenantType(userData, 'is_onboarding')
  const [navConfig, setNavConfig] = useState(navigationConfig)
  const location = useLocation();
  const navigate = useNavigate();
  const [azState] = useContext(GlobalState);
  const isParent = checkTenantType(userData, 'is_parent')

  const userMenuConfig = [
    {text: "PREFERENCES", function: () => navigate("/preferences")},
    {text: "SIGN OUT", function: () => handleSignOut()},
  ];

  useEffect(() => {
    const resetActiveNavItem = (navigationConfig) => {
      navigationConfig.some((item) => {
        return item.path === location.pathname
          ? setActiveNavItem(item.menuText)
          : Array.isArray(item.sub) && resetActiveNavItem(item.sub);
      });
    };
    resetActiveNavItem(navConfig);
  }, [location.pathname, navConfig]);

  useEffect(() => {
    let logSearchEnabled = showLogSearch(userData.tenantConfig, userData.userType)
    const structureConfig = () => {
      let tempConfig = (isParent || isOnboarding) ? parentNavigationConfig : navConfig
      tempConfig.map((item) => {
        if (item.menuText === 'ACTIVITY' && item.sub) {
          // Remove nav subfolder items if feature flag not enabled
          if (!logSearchEnabled) {
            item.sub = item.sub.filter(val => val.menuText !== 'LOG SEARCH')
          }
        }
        return item
      })
      setNavConfig(tempConfig)
    }
    userData.tenantConfig && (azState?.flags?.hasOwnProperty('hasNewVulnAccess') || !logSearchEnabled) && structureConfig()
    // eslint-disable-next-line
  }, [userData.tenantConfig, navConfig, userData.userType, isParent, userData, azState?.flags])

  useEffect(() => {
    const getAnnouncementList = async () => {
      try {
        let announcementItems = await getAnnouncements(userData.tenant, userData.email);
        setAnnouncementList(announcementItems.results);
      } catch (e) {
        captureSentryError(e, userData, 'getAnnouncements')
      }
    }
    userData.tenant && getAnnouncementList();
    // eslint-disable-next-line
  }, [userData.tenant]);

  useEffect(() => {
    const tenant = localStorage.getItem("tenant");
    setUserData((state) => ({...state, tenant: tenant}));
  }, [setUserData])


  useEffect(() => {
    userData?.userTenants && setTenantList(userData.userTenants)
    // eslint-disable-next-line
  }, [userData?.userTenants]);

  const selectTenantFromDropdown = async (selected) => {
    window.history.replaceState({}, document.title)
    ReactGA.gtag("set", "user_properties", {"Tenant": selected});
    localStorage.setItem('tenant', selected);
    navigate(0);
  }

  useEffect(() => {
    if (tenantList.length > 1 && (localStorage.getItem("tenant") || userData.tenant)) setDefaultTenant(getDefaultTenant());
    // eslint-disable-next-line
  }, [userData]);

  const getDefaultTenant = () => {
    let label;
    let role;
    let is_onboarding
    let tenant = localStorage.getItem('tenant') || userData?.tenant;
    let defaultTenant = tenantList.filter(item => item.id === tenant)
    if (defaultTenant.length > 0) {
      label = defaultTenant[0].label;
      role = defaultTenant[0].role;
      is_onboarding = defaultTenant[0].is_onboarding
    } else {
      label = tenantList[0].label;
      role = tenantList[0].role
      is_onboarding = tenantList[0].is_onboarding
    }
    return {
      label: label,
      value: tenant,
      id: tenant,
      name: tenant,
      role: role,
      is_onboarding: is_onboarding
    }
  }

  function forceNavItemClose(event) {
    if (event.target.className === "nav-right" || event.target.className === "nav-left")
      setActiveDropdown("")
  }

  const handleClickTrack = (category) => trackClick(userData, category, "click");

  return (
    <nav
      className={"nav-container"}
      style={{backgroundImage: `url('/graphic-header.png')`}}
      onMouseOver={forceNavItemClose}
    >
      {showAnnouncements && (
        <AnnouncementModal
          setOpen={setShowAnnouncements}
          contentList={announcementList}
          setContent={setAnnouncementList}
          userData={userData}
        />
      )}
      <div className={(isParent || isOnboarding) ? "nav-left parent" : "nav-left"}>
        <ul className={"nav-menu-list"}>
          <Link to={"/"}>
            <div className={"nav-logo"} onMouseOver={() => setActiveDropdown("")}>
              <img src="/ActZero-Logo_rev.svg" alt="ActZero"/>
            </div>
          </Link>
          {navConfig.map((item, index) => {
            const isMDRLite = userData?.tenantConfig?.is_mdr_lite
            const isMobileOnly = userData?.tenantConfig?.is_mobile_edr_only;
            const disableMDRLiteAndMobile = (name) => {
              if (isMDRLite || isMobileOnly) {
                const routes = ['RISK MANAGEMENT', 'VULNERABILITIES', 'CLOUD POSTURE', 'MATURITY ASSESSMENT', 'LOG SEARCH']
                isMobileOnly && routes.push('ENDPOINTS')
                return routes.includes(name) ? ' disabled-nav' : ''
              } else return ''
            }
            return (
              <div className={"nav-item-container"} key={index}>
                {item.path ? (
                  <Link to={item.path} key={item.path}>
                  <span
                    className={
                      "nav-item path" +
                      (activeNavItem === item.menuText ? " is-active " : "") +
                      (activeDropdown === item.menuText && item?.sub?.length > 0
                        ? " active-dropdown"
                        : "")
                    }
                    onMouseOver={() => setActiveDropdown(item.menuText)}
                    onClick={() => setActiveNavItem(item.menuText)}
                  >
                    <span
                      className={
                        (isOnboarding && item.menuText !== 'DASHBOARD') ? "parent" :
                          (isParent && item.menuText === 'DASHBOARD') ?
                            'child enabled' : "parent"}
                    >
                      {item.menuText}
                    </span>
                  </span>
                  </Link>
                ) : (
                  <span
                    className={"nav-item" + (activeNavItem === item.menuText ? " is-active " : "") +
                    (activeDropdown === item.menuText && item?.sub?.length > 0 ? " active-dropdown" : "")
                    }
                    onMouseOver={() => setActiveDropdown(item.menuText)}
                  >
                  <span
                    className={(isParent || isOnboarding) ? "parent" : "child"}>
                    {item.menuText}
                  </span>
                </span>
                )}
                <div className={"dropdown-container"} onMouseLeave={() => setActiveDropdown("")}>
                  {activeDropdown === item.menuText && item?.sub && (
                    <DropdownNav
                      isMDRLite={isMDRLite}
                      isMobileOnly={isMobileOnly}
                      disableMDRLiteAndMobile={disableMDRLiteAndMobile}
                      setActiveDropdown={setActiveDropdown}
                      data={item?.sub}
                      indent={indent}
                      activeNavItem={activeNavItem}
                      setActiveNavItem={setActiveNavItem}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </ul>
      </div>
      <div className="nav-right">
        <div className={"breach-info"}>
          <p className={"breach-number"}><span>BREACH?</span> 1-855-917-4981</p>
          <p
            className={"pointer breach-email"}
            onClick={() => window.location = 'mailto:threathunting@actzero.ai'}
          >threathunting@actzero.ai</p>
        </div>
        {tenantList.length > 1 && (
          <TenantDropdown
            styles={tenantDropdownStyles}
            data={tenantList}
            onChange={selectTenantFromDropdown}
            defaultValue={defaultTenant}
            className={'tenant-dropdown'}
          />
        )}
        <div
          onMouseOver={() => setActiveDropdown("")}
          className="announcement-container"
          onClick={() => {
            if (announcementList.length) {
              setShowAnnouncements(true);
              handleClickTrack("Active Announcements Button");
            } else {
              handleClickTrack("Inactive Announcements Button");
            }
          }}>
          <AnnouncementBtn count={announcementList.filter(announcement => !announcement.is_read).length}/>
        </div>
        <div className="help-container">
          <span
            onClick={() => activeDropdown === "help" ? setActiveDropdown("") : setActiveDropdown("help")}
            className={"help-btn " + (activeNavItem === "help" ? "is-active " : "") +
            (activeDropdown === "help" ? " active-dropdown" : "")
            }
            onMouseOver={() => setActiveDropdown("help")}
          >
            <span>{"HELP"}</span>
          </span>

          <div
            className="help-dropdown"
            onMouseLeave={() => setActiveDropdown("")}
          >
            {activeDropdown === "help" && (
              <DropdownNav
                setActiveDropdown={setActiveDropdown}
                data={helpConfig}
                indent={indent}
                activeNavItem={activeNavItem}
                setActiveNavItem={setActiveNavItem}
                handleClickTrack={handleClickTrack}
                activeDropdown={activeDropdown}
                disableMDRLiteAndMobile={() => {
                }}
              />
            )}
          </div>
        </div>
        {hasAdminAccess(userData?.role) && <ToolsMenu
          activeDropdown={activeDropdown}
          setActiveDropdown={setActiveDropdown}
          activeNavItem={activeNavItem}
          setActiveNavItem={setActiveNavItem}
          userData={userData}
          handleClickTrack={handleClickTrack}
        />}
        <div
          className="user-menu-container"
          onMouseLeave={() => setActiveDropdown("")}
          onMouseOver={() => setActiveDropdown("user-menu")}
          onClick={() =>
            activeDropdown === "user-menu"
              ? setActiveDropdown("")
              : setActiveDropdown("user-menu")
          }
        >
          <div className={"user-icon"}>
            <span>
              {userData?.email ? userData?.email.substring(0, 1).toUpperCase() : "A"}
            </span>
          </div>
          {activeDropdown === "user-menu" && (
            <ul className="user-menu">
              {userMenuConfig.map((opt) => (
                <li key={opt.text}>
                  <button className="user-menu-btn" onClick={opt.function}>
                    {opt.text}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
