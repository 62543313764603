export const mainRow = {
  "Detection Time": {"field": "last_seen", "header": "TIME", "sort": true, "defaultSortValue": false},
  "Hostname": {"field": "hostname", "header": "HOSTNAME", "sort": true, "defaultSortValue": false},
  "Status": {"field": "status", "header": "STATUS", "sort": true, "defaultSortValue": false},
  "OS_type": {"field": "os_platform_name", "header": "OS TYPE","sort": true, "defaultSortValue": false},
  "OS": {"field": "os_version", "header": "OS VERSION","sort": true, "defaultSortValue": false},
  "Firewall": {"field": "Firewall Enabled", "header": "FIREWALL", "sort": true, "defaultSortValue": false},
  "Encryption": {"field": "Encryption Enabled", "header": "ENCRYPTION", "sort": true, "defaultSortValue": false},
  "Missing Patches": {"field": "Missing Patches", "header": "MISSING UPDATES", "sort": true, "defaultSortValue": false},
  "EDR": {"field": "EDR VERSION", "header": "EDR AGENT VERSION", "sort": true, "defaultSortValue": false},
  "Profiler": {"field": "Profiler VERSION", "header": "PROFILER VERSION", "sort": true, "defaultSortValue": false}
}

export const rightColumn = {
  "crowdstrike": {
    "": {
      "Lockout Threshold": {"field": "lockout_threshold", "transform": "addNA", "display": "Lockout Threshold"},
      "Centrally Managed": {"field": "centrally_managed", "transform": "addNA", "display": "Centrally Managed"},
      "Maintenance Token": {"field": "maintenance_token", "display": "Maintenance Token"}
    }
  },
  "defender": {},
  "SentinelOne": {}
}

export const leftColumn = { 
  "crowdstrike": {
    "": {
      "IP Address": {"field": "ip_address", "display": "IP Address"},
      "MAC Address": {"field": "mac_address", "display": "MAC Address"},
      "Max Password Age": {"field": "max_password_age", "transform": "addNA", "display": "Max Password Age"},
      "Users": {"field": "users", "transform": "getUsers", "display": "Users"}
     }
  },
  "defender": {
    "": {
      "IP Address": {"field": "ip_address", "display": "IP Address"},
      "MAC Address": {"field": "mac_address", "display": "MAC Address"},
      "Users": {"field": "users", "transform": "getUsers", "display": "Users"}
    }
  },
  "SentinelOne": {
    "": {
      "Agent ID": {"field": "edr_endpoint_id", "transform": "addNA", "display": "Agent ID"}
    }
  }
}
