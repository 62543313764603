import "../../../styles/pages/Onboarding/Tenants/TenantsOnboarding.scss";
import React, { useState, useEffect, useContext } from "react";
import UserData from "../../../store/User/UserData";
import GlobalState from "../../../store/GlobalState/GlobalState";
import { trackPageView } from "../../../util/analytics";
import AscSorting from "../../../assets/icons/triangle-up.svg";
import DescSorting from "../../../assets/icons/triangle-down.svg";
import DenseTable from "../../../components/Table/DenseTable";
import { getTenantOnboardingData } from "../../../api/ApiClient";
import { sortObjects } from "../../../util/sortObjects";
import NotFound from "../../../navigation/NotFound";
import TenantDetailsModal from "./TenantDetails.js/TenantDetailsModal";
import NewChildTenant from "./NewChildTenant";
import { isAZUser } from "../../../util/userControl";
import EditIcon from "@mui/icons-material/Edit";
import { captureSentryError } from "../../../util/sentry";
import { convertDataSize, getDataUnit } from "../../../util/format";
import { tableHeaders, getEnabledIcon } from "./config";

const TenantsOnboarding = ({ navigateToChildTenant }) => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [tenantData, setTenantData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState();
  const [showAddTenantModal, setShowAddTenantModal] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [fetchError, setFetchError] = useState(false);
  const [modalTenant, setModalTenant] = useState(false);
  const hasTenantRequestAccess = isAZUser(userData) || userData?.tenant === 'c1st-parent' || userData?.tenant === 'lockmann-parent'

  useEffect(
    () => trackPageView("Tenant Onboarding", userData, azState),
    // eslint-disable-next-line
    [userData?.userId, azState.reactGaInitializedState]
  );

  useEffect(() => {
    userData.tenant && getTenantData();
    // eslint-disable-next-line
  }, [userData?.tenant]);

  useEffect(() => {
    setTenantData(sortObjects(tenantData, sortBy, sortOrder));
    // eslint-disable-next-line
  }, [sortBy, sortOrder]);

  const toggleSortOrder = () =>
    sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  async function getTenantData() {
    try {
      setFetchError(false);
      setLoading(true);
      const { results } = await getTenantOnboardingData(userData?.tenant);
      const mappedData = results.map((tenantStats) => {
        return {
          id: tenantStats.tenant.id,
          name: tenantStats.tenant?.name
            ? tenantStats.tenant.name
            : tenantStats.tenant.id,
          endpointsCount: tenantStats.endpoints.count,
          contractedCount: tenantStats.endpoints.contracted,
          cloudCount: tenantStats.cloud.count,
          mobileCount: tenantStats.mobile.count,
          onboardingStatus: tenantStats.onboarding.status,
          identityCount: tenantStats.identity.count,
          logVolume: `${convertDataSize(
            tenantStats.log_management.volume,
            2
          )} ${getDataUnit(tenantStats.log_management.volume)}`,
          evm: tenantStats.evm.enabled,
          deviceControl: tenantStats.device_control.enabled,
        };
      });
      setTenantData(sortObjects(mappedData, "name", "asc"));
      setLoading(false);
    } catch (e) {
      captureSentryError(e, userData, "getTenantOnboardingData API in TenantsOnboarding.js");
      setFetchError(true);
    }
  }

  const generateHeaderRow = () => (
    <>
      <td className="table-column checkbox-placeholder" />
      {Object.keys(tableHeaders).map((col) => {
        return (
          <td className={`table-column ${col}`} key={col}>
            <div
              onClick={() => {
                sortBy === col ? toggleSortOrder() : setSortBy(col);
              }}
              className={"clickable" + (sortBy === col ? " active" : "")}
            >
              {tableHeaders[col].toUpperCase()}
              <span className="sort-icon">
                {sortBy === col && sortOrder === "asc" ? (
                  <img className={"asc"} src={AscSorting} alt="" />
                ) : sortBy === col && sortOrder === "desc" ? (
                  <img src={DescSorting} alt="" />
                ) : null}
              </span>
            </div>
          </td>
        );
      })}
      <td className="table-column edit-column" />
    </>
  );

  const generateBodyRows = () =>
    tenantData.map((row) => (
      <tr className="tenant-row" key={row.id}>
        <td className="checkbox-placeholder" />
        {Object.keys(tableHeaders).map((col) => (
          <td key={`${col}`}>
            <span
              className={`tenant-cell ${col} ${row[col]}`}
              onClick={col === "name" ? () => navigateToChildTenant(row) : null}
            >
              {col === "evm" || col === "deviceControl" ? (
                getEnabledIcon(row[col])
              ) : (
                <>
                  {row[col]}
                  {col === "name" ? " »" : ""}
                </>
              )}
            </span>
          </td>
        ))}
        <td className="tenant-cell edit-btn">
          <EditIcon className="open-modal-btn" onClick={() => setModalTenant(row.id)} />
        </td>
      </tr>
    ));

  return (
    <div className="tenants-onboarding-content">
      {modalTenant && <TenantDetailsModal tenantId={modalTenant} closeFunction={() => setModalTenant()} refreshData={() => getTenantData()} />}
      <div className="onboarding-title">
        <span className={`onboarding-title-text${showAddTenantModal ? " show" : ""}`}>TENANTS</span>
        {hasTenantRequestAccess && <div className={`add-tenant-button${showAddTenantModal ? " show" : ""}`} onClick={() => setShowAddTenantModal(prevState => { return !prevState })}>
          {showAddTenantModal ? 
            (<><span className={"add-tenant-icon show"}>«</span>BACK TO TENANT LIST</>) :
            (<>REQUEST TENANT<span className={"add-tenant-icon"}>»</span></>)
          }
        </div>}
      </div>
      {fetchError ? (
        <NotFound isError dataError className="panel" />
      ) : showAddTenantModal ? (<NewChildTenant setShowAddTenantModal={setShowAddTenantModal}/>) : (
        <div className="onboarding-table tenants">
          <DenseTable
            loading={loading}
            header={generateHeaderRow()}
            noDataMessage={"No users added yet."}
            body={generateBodyRows()}
          />
        </div>
      )}
    </div>
  );
};

export default TenantsOnboarding;
