import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import {Checkbox} from "@mui/material";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {hasWriteAccess} from "../../../util/userControl";
import Dropdown from "../../../components/Dropdown/Dropdown";
import ActionComment from "../ActionComment";
import {postUpdateAssetsActionOrComment} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";
import UserData from "../../../store/User/UserData";
import {actionOptions, actionTooltipConfig, updateAsset} from "../config/assetswithvulnerabilitiesconfig";
import {sorting} from "../../../config/mappingData";
import SeverityImage from "../../../components/Table/SeverityImage";
import {severities} from "../config/vulnerabilitytabconfig";

export const ExpandedViewTable = ({
                                    headers,
                                    data,
                                    className,
                                    page,
                                    setCurrTab,
                                    selectAllCheckboxes,
                                    setSelectAllCheckboxes,
                                    selectedCheckboxes,
                                    setSelectedCheckboxes,
                                    asset_name,
                                    sortTable,
                                    params
                                  }) => {
  const [userData] = useContext(UserData);
  const navigate = useNavigate();
  const [openCommentRowIndex, setOpenCommentRowIndex] = useState(null);
  const [existingComment, setExistingComment] = useState(data.map(d => d?.comment || ''));
  const [comments, setComments] = useState(data.map(d => d?.comment || ''));
  const [selectedActions, setSelectedActions] = useState(data.map(d => d.action_taken || 'New Risk'));
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState(false);

  const handleCheckboxChange = (index) => {
    const newSelectedCheckboxes = [...selectedCheckboxes];
    newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAllCheckboxes;
    setSelectAllCheckboxes(newSelectAll);
    setSelectedCheckboxes(Array(data.length).fill(newSelectAll));
  };

  const handleComments = (value, rowIndex) => {
    const updatedComments = comments.map((comment, index) => index === rowIndex ? value : comment);
    setComments(updatedComments);
  };

  const handleExistingComments = (value, rowIndex) => {
    existingComment[rowIndex] = value;
    setExistingComment([...existingComment]);
  };

  const handleClick = (item) => {
    setCurrTab('vulnerabilities')
    navigate('/risk-management/vulnerabilities2', {state: {cve: item.vulnerability_id}})
  }

  const openActionDropdown = (rowIndex, value) => setOpenDropdownIndex(value ? rowIndex : null);

  const selectActionFromDropdown = (rowIndex, value) => {
    setSelectedActions(actions => actions.map((action, index) => index === rowIndex ? value : action));
    openActionDropdown(rowIndex, false);
  };

  const addComment = async (value, rowIndex) => {
    setLoading(true);
    try {
      await postUpdateAssetsActionOrComment(userData?.tenant, asset_name, {asset_names: [asset_name], comment: value});
      const updatedComments = comments.map((comment, index) => index === rowIndex ? value : comment);
      setComments(updatedComments);
      const updatedExistingComments = existingComment.map((comment, index) => index === rowIndex ? value : comment);
      setExistingComment(updatedExistingComments);
      data[rowIndex].comment_last_updated = Math.floor((new Date()).getTime() / 1000);
      setOpenCommentRowIndex(null);
    } catch (e) {
      setCommentError(true);
      captureSentryError(e, userData, "postUpdateAssetsActionOrComment add comment");
    } finally {
      setLoading(false);
    }
  };

  return (
    <table className={"vulnerabilities-tab-table-container " + page}>
      <tbody className={"table-body"}>
      <tr className={"table-row headers " + className}>
        {Object.keys(headers).map((key, index) =>
          <th
            key={index}
            className={key === "title" ? "title-cell" : key}
            onClick={(e) => e.target.type !== 'checkbox' && sortTable(key)}
          >
            {key === 'action_taken' &&
            <Checkbox
              key={index + key}
              className="custom-checkbox select-all"
              checked={selectAllCheckboxes === true}
              onChange={(e) => {
                e.stopPropagation();
                handleSelectAllChange(e);
              }}
            />}
            {headers[key].toUpperCase()?.replace('_', ' ')}
            {params.sort_by === key && (params.order === 'asc' ?
              <img className={"asc caret"} src={sorting['Asc']} alt=""/> : params.order === 'desc' ?
                <img className={"desc caret"} src={sorting['Desc']} alt=""/> : null)}
          </th>)}
      </tr>
      {data.map((obj, index) =>
        <tr className={"table-row row-value " + className} key={index} onClick={className !== 'patches' && (() => handleClick(obj))}>
          {Object.keys(headers).map((item, i) => item === 'action_taken' ?
            <td className="Action td-value" key={i + item} onClick={(e) => e.stopPropagation()}>
              <div className="dropdown-sort dropdown-container">
                <Checkbox
                  key={item + index}
                  className="custom-checkbox"
                  checked={selectedCheckboxes[index] === true}
                  onChange={() => handleCheckboxChange(index)}
                />
                <Tooltip
                  content={actionTooltipConfig[selectedActions[index]] || ''}
                  className={`title-tooltip ${openDropdownIndex === index ? 'show' : ''}`}
                  direction="bottom"
                  type="action-value"
                  disable={openDropdownIndex === index}
                >
                  {selectedActions[index] === 'Risk Mitigated' || !hasWriteAccess(userData?.role) ?
                    <p className="risk-mitigated-title">{selectedActions[index]}</p>
                    : <Dropdown
                      className="action-dropdown"
                      name={selectedActions[index]}
                      dropdownList={actionOptions}
                      selectItem={(value) =>
                        updateAsset(value, index, selectedCheckboxes,
                          selectActionFromDropdown, asset_name, data, userData, false, setLoading)
                      }
                      selectedItem={selectedActions[index]}
                      showDropdown={openDropdownIndex === index}
                      setShowDropdown={(value, e) => {
                        e.stopPropagation();
                        openActionDropdown(index, value, e)
                      }}
                    />}
                </Tooltip>
                {openCommentRowIndex === index &&
                <ActionComment
                  submitComment={(value) => addComment(value, index)}
                  commentValue={comments[index]}
                  commentLastUpdated={data[index]?.comment_last_updated}
                  setCurrentComment={(value) => handleComments(value, index)}
                  close={async () => {
                    setOpenCommentRowIndex(null);
                    handleExistingComments(comments[index], index)
                    setCommentError(false);
                  }}
                  index={index}
                  existingComment={existingComment[index]}
                  loading={loading}
                  setExistingComment={handleExistingComments}
                  commentError={commentError}
                />}
              </div>
            </td> : item === 'comment' ?
              <td
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenCommentRowIndex(openCommentRowIndex === index ? null : index)
                }}
                className={item}
              >{(existingComment[index] !== '' || comments[index] !== '') ? 'VIEW COMMENT' : 'ADD COMMENT'}
              </td> : (item === 'description' || item === 'title') ?
                <td>
                  <Tooltip content={obj[item]} className={`description-tooltip`} direction={"bottom"} disableTimeout>
                    <div className={item}>{obj[item]}</div>
                  </Tooltip>
                </td> :
                <td className={item === "title" ? "title-cell" : item} key={i}>{item === 'severity' ?
                  <SeverityImage severities={severities} severity={obj[item]}/> :
                  obj[item]}
                </td>)}
        </tr>)}
      </tbody>
    </table>)
}
