import { requiredParameters, defaultInputs } from "./config";
import {
  addOnboardingContact,
  editOnboardingContact,
  deleteOnboardingContact,
  resetOnboardingContact,
  resendInviteOnboardingContact,
  resetUserMFASetting,
} from "../../../api/ApiClient";
import { trackClick } from "../../../util/analytics";
import _ from "lodash";
import { captureSentryError } from "../../../util/sentry";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const mapParams = (src) => {
  return {
    first_name: src.firstname.trim(),
    last_name: src.lastname.trim(),
    email: src.email,
    phone_number: src.mobilePhone,
    job_title: src.jobTitle.trim(),
    escalation_type: src.escalationType,
    contact_type: src.contactType,
    user_role: src.userRole,
  };
};

export const verifyParams = (params, setErrorFunction) => {
  const paramKeys = Object.keys(requiredParameters);
  for (let i in paramKeys) {
    const key = paramKeys[i];
    const value = params[key];

    let isValid =
      key === "email"
        ? value.toLowerCase().match(
            // eslint-disable-next-line
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          )
        : key === "mobilePhone"
        ? isPossiblePhoneNumber(value)
        : !value
        ? value
        : value.length;

    if (!isValid) {
      setErrorFunction(requiredParameters[key]);
      return false;
    }
  }
  if (
    params.userRole &&
    (params.userRole === "Admin" ||
      params.userRole === "Read_Only" ||
      params.userRole === "Read_Write") &&
    !params.mobilePhone
  ) {
    setErrorFunction("Must enter mobile phone for portal users");
    return false;
  }

  return true;
};

export const handleAddUser = async (
  userData,
  addedUserTenant,
  setContactsUserData,
  setAddError,
  setIsUploading,
  addUserInputs,
  setAddUserInputs
) => {
  setAddError();
  setIsUploading(true);
  let validated = verifyParams(addUserInputs, setAddError);
  const params = mapParams(addUserInputs);
  if (validated)
    try {
      const results = await addOnboardingContact(addedUserTenant, params);
      if (!results[0].success) setAddError(results[0].error);
      else {
        setContactsUserData((prevState) => [addUserInputs, ...prevState]);
        setAddUserInputs(defaultInputs);
        trackClick(
          userData,
          `added contacts onboarding user ${params.email}`,
          "click"
        );
      }
    } catch (e) {
      setAddError("Error adding user");
      captureSentryError(
        e,
        userData,
        "addOnboardingContact in Onboarding/Contacts/util.js"
      );
    }
  setIsUploading(false);
};

export const handleEditUser = async (
  setRowLoading,
  setEditError,
  setPendingRow,
  editUserInputs,
  userData,
  editedUserTenant,
  setContactsUserData,
  resetPendingStage,
  setTotpEnabledUsers
) => {
  setRowLoading(true);
  setEditError();
  setPendingRow((prevState) => {
    let newState = { ...prevState };
    newState.row = params;
    return newState;
  });
  const validated = verifyParams(editUserInputs, setEditError);
  const params = mapParams(editUserInputs);
  if (validated)
    try {
      const results = await editOnboardingContact(editedUserTenant, params);
      if (!results[0].success) setEditError(results[0].error);
      else {
        setContactsUserData((prevState) => {
          let index = _.findIndex(
            prevState,
            (e) => e.email === results[0].email
          );
          prevState[index] = editUserInputs;
          return prevState;
        });
        params.mfa_setting !== "totp" &&
          setTotpEnabledUsers((userList) =>
            userList.filter((email) => email !== results[0].email)
          );
        resetPendingStage();
        trackClick(
          userData,
          `edited contacts onboarding user ${params.email}`,
          "click"
        );
      }
    } catch (e) {
      setEditError("Error editing user");
      captureSentryError(
        e,
        userData,
        "editOnboardingContact in Onboarding/Contacts/util.js"
      );
    }
  setRowLoading(false);
};

export const handleDeleteUsers = async (
  isMultiple,
  setRowLoading,
  selectedRows,
  userData,
  deletedUserTenant,
  setModalErrors,
  setSelectedRows,
  setShowConfirmDeleteModal,
  getContactsUserData,
  pendingRow,
  setRowError,
  resetPendingStage,
  setContactsUserData,
  setActionError,
) => {
  setRowLoading(true);
  try {
    if (isMultiple) {
      const params = selectedRows.map((r) => {
        return { email: r.email };
      });
      const results = await deleteOnboardingContact(deletedUserTenant, params);
      const failures = results.filter((r) => !r.success);
      if (failures.length)
        setModalErrors(failures.map((e) => `${e.email} - ${e.error}`));
      else {
        setSelectedRows([]);
        setShowConfirmDeleteModal();
        trackClick(
          userData,
          `deleted multiple contacts onboarding users ${results.map(
            (r) => `${r.email}`
          )}`,
          "click"
        );
      }
      getContactsUserData();
    } else {
      const results = await deleteOnboardingContact(deletedUserTenant, [
        { email: pendingRow.row.email },
      ]);
      if (!results[0].success) setRowError("Error deleting user");
      else {
        resetPendingStage();
        setContactsUserData((prevState) =>
          prevState.filter((u) => u.email !== results[0].email)
        );
        trackClick(
          userData,
          `deleted contacts onboarding user ${results[0].email}`,
          "click"
        );
      }
    }
  } catch (e) {
    isMultiple
      ? setActionError(
          `Error deleting user${selectedRows.length > 1 ? "s" : ""}`
        )
      : setRowError("Error deleting user");
    captureSentryError(
      e,
      userData,
      "deleteOnboardingContact in Onboarding/Contacts/util.js"
    );
  }
  setRowLoading(false);
};

export const handleResetAccount = async (
  isMultiple,
  setRowLoading,
  selectedRows,
  userData,
  resetUserTenant,
  setModalErrors,
  setSelectedRows,
  pendingRow,
  setRowError,
  resetPendingStage,
  setActionError,
  resetModal
) => {
  setRowLoading(true);
  try {
    if (isMultiple) {
      const params = selectedRows.map((r) => ({
        email: r.email,
      }));
      const results = await resetOnboardingContact(resetUserTenant, params);
      const failures = results.filter((r) => !r.success);
      if (failures.length)
        setModalErrors(failures.map((e) => `${e.email} - ${e.error}`));
      else {
        setSelectedRows([]);
        resetModal();
        trackClick(
          userData,
          `reset account of contacts onboarding users ${results.map(
            (r) => `${r.email}`
          )}`,
          "click"
        );
      }
    } else {
      const results = await resetOnboardingContact(resetUserTenant, [
        {
          email: pendingRow.row.email,
        },
      ]);
      if (!results[0].success) setRowError("Error resetting user");
      else {
        resetPendingStage();
        trackClick(
          userData,
          `reset contacts onboarding user account ${results[0].email}`,
          "click"
        );
      }
    }
  } catch (e) {
    isMultiple
      ? setActionError(
          `Error resending invite${selectedRows.length > 1 ? "s" : ""}`
        )
      : setRowError("Error resetting user");
    captureSentryError(
      e,
      userData,
      "resetOnboardingContact in Onboarding/Contacts/util.js"
    );
  }
  setRowLoading(false);
};

export const handleResendInvite = async (
  isMultiple,
  setRowLoading,
  selectedRows,
  userData,
  resendInviteUserTenant,
  setModalErrors,
  setSelectedRows,
  pendingRow,
  setRowError,
  resetPendingStage,
  setActionError,
  resetModal
) => {
  setRowLoading(true);
  try {
    if (isMultiple) {
      const params = selectedRows.map((r) => ({
        email: r.email,
      }));
      const results = await resendInviteOnboardingContact(
        resendInviteUserTenant,
        params
      );
      const failures = results.filter((r) => !r.success);
      if (failures?.length > 0)
        setModalErrors(failures.map((e) => `${e.email} - ${e.error}`));
      else {
        setSelectedRows([]);
        resetModal();
        trackClick(
          userData,
          `resend invite of contacts onboarding users ${results.map(
            (r) => `${r.email}`
          )}`,
          "click"
        );
      }
    } else {
      const results = await resendInviteOnboardingContact(
        resendInviteUserTenant,
        [
          {
            email: pendingRow.row.email,
          },
        ]
      );
      if (!results[0].success) setRowError("Error resending invite");
      else {
        resetPendingStage();
        trackClick(
          userData,
          `reset contacts onboarding user account ${results[0].email}`,
          "click"
        );
      }
    }
  } catch (e) {
    isMultiple
      ? setActionError(
          `Error resending invite${selectedRows.length > 1 ? "s" : ""}`
        )
      : setRowError("Error resending invite");
    captureSentryError(
      e,
      userData,
      "resendInviteOnboardingContact in Onboarding/Contacts/util.js"
    );
  }
  setRowLoading(false);
};

export const handleResetMFA = async (setRowLoading, pendingRow, setRowError, resetPendingStage, userData) => {
  setRowLoading(true);
  try {
    const results = await resetUserMFASetting(userData?.tenant, {email: pendingRow.row.email});
    if (!results.success) setRowError("Error resetting MFA");
    else {
      resetPendingStage();
      trackClick(
        userData,
        `reset MFA of user account ${results.email}`,
        "click"
      );
    }
  } catch (e) { 
    setRowError("Error resetting MFA");
    captureSentryError(
      e,
      userData,
      "resetUserMFASetting in Onboarding/Contacts/util.js"
    );
  }
  setRowLoading(false);
};
