import React from 'react';
import '../../styles/components/Dropdown/GenericCheckbox.scss';
import Checkbox from "react-custom-checkbox";
import Check from '../../assets/icons/checkmark.svg';
import { capitalize } from '../../util/format';


const GenericCheckbox = ({isOpen, dropdownList, selectCheckbox, name, data, labelStyle}) => {
  return (
      <div>
      {
        isOpen && Object.keys(dropdownList).map((item, index) => (
          <div className={"checkbox-container"} onClick={() => selectCheckbox(name, item)} key={index}>
            <Checkbox
              className={"checkbox"}
              checked={data[name]?.filters[item]}
              icon={<img
                src={Check}
                className={"checkbox-icon"}
                alt={item}
              />}
              borderColor="rgba(255, 255, 255, 0.6)"
              borderRadius={1}
              borderWidth={1}
              size={12}
              label={data[name]?.capitalize ? capitalize(item) : item}
              labelStyle={labelStyle}
              style={{cursor: 'pointer'}}
              labelClassName={`generic-checkbox-label ${item}`}
            />
          </div>)
        )
      }
      </div>
  )
}

export default GenericCheckbox;
