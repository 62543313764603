import {signOut} from "aws-amplify/auth";
import { Cache } from 'aws-amplify/utils';

export const handleSignOut = () => {
    const tenant = localStorage.getItem("tenant");
    localStorage.clear();
    Cache.clear();
    signOut();
    localStorage.setItem('tenant', tenant);
    window.location.href = "/";
  };