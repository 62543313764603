                                                                      import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import '../../../../styles/pages/Onboarding/Cloud/RadioSelection.scss';


const RadioSelection = ({
                         options,
                         handleChange,
                         selected,
                         className,
                         icon,
                         checkedIcon
                        }) => {

  return (
    <div className={className}>
      {options?.map((option, index) => (
        <div className={"radio-selection"} key={option+index} onClick={(event) => handleChange(event, option.name, option.options)}>
        <MenuItem key={index} className={className}>
          <ListItemIcon>
            <Checkbox
              key={index}
              className={"radio-selection-circle"}
              onClick={(event) => handleChange(event, option.name)}
              icon={icon}
              checkedIcon={checkedIcon}
              value={option.name}
              checked={option.name === selected || option.value === selected ? true : false}
            />
          </ListItemIcon>
          <ListItemText
              key={index}
              primary={option.value}
              onClick={(event) => handleChange(event, option.name, option.options)}
              className={"radio-selection-text"}
            />
        </MenuItem>
         {option.description && <div className="radio-selection-description" key={"radio-selection-description"}>
             {option.description}
          </div>}
        </div>
      ))}
    </div>
  );
}

export default RadioSelection;