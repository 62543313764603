import React, {useContext, useEffect, useState} from "react";
import "../../styles/pages/Network/Network.scss";
import UserData from "../../store/User/UserData";
import MapProjection from "./components/MapProjection";
import NetworkMetrics from "./components/NetworkMetrics";
import {getNetworkMetrics} from "../../api/ApiClient";
import TrafficLineChart from "./components/TrafficLineChart";
import TopCountriesTable from "./components/TopCountriesTable";
import {exportCSV, setMapData, setTrendsData} from "./util";
import {captureSentryError} from "../../util/sentry";
import NetworkDropdown from "./components/NetworkDropdown";
import {dropdownConfig, mapRenderConfig, networkPageTooltip} from "./config/config";
import ConnectionsModal from "../Connections/ConnectionsModal";
import {Loader} from "../../components/Loader/Loader";
import Tooltip from "../../components/Tooltip/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateFilters from "./components/DateFilters";
import moment from "moment";

const Network = () => {
  const [userData] = useContext(UserData);
  const [mapStatistics, setMapStatistics] = useState({
    incoming: [],
    outgoing: [],
  });
  const [topCountriesData, setTopCountriesData] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [networkMetricsData, setNetworkMetricsData] = useState();
  const [trafficAxisData, setTrafficAxisData] = useState();
  const [trafficData, setTrafficData] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [mapsLoading, setMapsLoading] = useState(true);
  const [trendsLoading, setTrendsLoading] = useState(true);
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [filters, setFilters] = useState({
    start_time: moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    end_time: null,
    source: null,
    action: null,
  });

  useEffect(() => {
    const fetchMetricsData = async () => {
      setMetricsLoading(true);
      try {
        const results = await getNetworkMetrics(userData?.tenant);
        setNetworkMetricsData(results);
      } catch (e) {
        captureSentryError(e, userData, "getNetworkMetrics");
      } finally {
        setMetricsLoading(false);
      }
    };

    userData.tenant && fetchMetricsData();
  }, [userData]);

  useEffect(() => {
    const fetchMapData = async () => {
      setMapsLoading(true);
      try {
        userData && filters?.end_time && await setMapData({userData, setMapStatistics, filters});
      } catch (e) {
        captureSentryError(e, userData, "getNetworkData");
      } finally {
        setMapsLoading(false);
      }
    };

    const fetchTrendData = async () => {
      setTrendsLoading(true);
      try {
        await setTrendsData({
          userData,
          filters,
          setTopCountriesData,
          setTrafficData,
          setTrafficAxisData,
          setAllCountries,
        });
      } catch (e) {
        captureSentryError(e, userData, "getNetworkTrends");
      } finally {
        setTrendsLoading(false);
      }
    };

    if (userData.tenant) {
      fetchMapData();
      fetchTrendData();
    }

  }, [userData, filters]);

  const timeAgo = (dateString) => {
    const diffInDays = (new Date() - new Date(dateString)) / (1000 * 60 * 60 * 24);
    return diffInDays <= 31 ? '%d' : diffInDays > 31 && diffInDays <= 60 ? '%m/%d' : '%b'
  };

  return (
    <div className={"network-page-container"}>
      <div className={"header-row"}>
        <p className={"page-title"}>NETWORK</p>
        <div className="page-tooltip">
          <Tooltip
            content={networkPageTooltip}
            direction="bottom"
            disableTimeout
          >
            <InfoOutlinedIcon className="info-icon"/>
          </Tooltip>
        </div>
        <div className={"btn-row"}>
          <div
            className={"menu-btn export-btn"}
            onClick={() => exportCSV({userData})}
          >
            {"Export »"}
          </div>
          <div
            className={"menu-btn modal"}
            onClick={() => setModalOpen("firewall-list")}
          >
            {"Network device list »"}
          </div>
          {modalOpen === "firewall-list" && (
            <ConnectionsModal close={setModalOpen} title={"Network Device List"}/>
          )}
          {dropdownConfig.map((config) => (
            <NetworkDropdown
              key={config.value}
              config={config}
              setFilters={setFilters}
              filters={filters}
            />
          ))}
          <DateFilters setFilters={setFilters} filters={filters}/>
        </div>
      </div>
      <div className="maps-container">
        {mapsLoading ? (
          <div className="left-loader">
            <Loader/>
          </div>
        ) : (
          <>
            {mapRenderConfig.map((config) => (
              <MapProjection
                key={config.type}
                mapStatistics={mapStatistics[config.type]}
                type={config.type}
              />
            ))}
          </>
        )}
      </div>
      <div className="network-metrics-right">
        <div className="network-metrics-container">
          {metricsLoading ? (
            <Loader/>
          ) : (
            <NetworkMetrics data={networkMetricsData}/>
          )}
        </div>
        <div className="network-trends-container">
          {trendsLoading ? (
            <Loader/>
          ) : (
            <>
              <TrafficLineChart
                timeFormat={timeAgo(filters?.start_time)}
                mappedGraphData={trafficData}
                axisData={trafficAxisData}
              />
              <TopCountriesTable
                topCountriesData={topCountriesData}
                allCountries={allCountries}
                setTopCountries={setTopCountriesData}
              />
              <div className="legend">
                <div>{"VALUES REPRESENT NUMBER OF CONNECTIONS"}</div>
                <div>{"PRIVATE IP TO PRIVATE IP TRAFFIC NOT COUNTED"}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Network;
