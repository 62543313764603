import TrashIcon from "../../../assets/icons/trash-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";

export const tabConfig = [
  { display: "External IPs", configName: "external_scans" },
  { display: "Internal IPs", configName: "internal_scans" },
];

export const tableHeaders = {
  external_scans: {
    scan_name: "Scan Name",
    scan_ips: "External IP Scan Range",
    scan_day_of_month: "Scan Day of Month",
  },
  internal_scans: {
    scan_name: "Scan Name",
    scan_ips: "Internal IP Scan Range",
    scan_day_of_month: "Scan Day of Month",
  },
};

export const rowButtonConfig = [
  { action: "delete", tooltip: "Delete", icon: TrashIcon },
  { action: "edit", tooltip: "Edit", icon: EditIcon },
];

export const confirmDialogs = {
  delete: "Confirm delete?",
};


export const searchableDropdownStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#D0F31B' : '#fff',
    padding: 10,
    cursor: 'pointer',
    backgroundColor: "#094650",
    border: state.isFocused ? "1px solid #018D8D" : "1px solid #094650",
  }),
  control: () => ({
    width: 270,
    color: '#D0F31B',
    display: 'flex',
    border: '1px solid #C0D0D0',
    cursor: 'pointer'
  }),
  singleValue: (provided) => {
    const color = 'white';
    return {...provided, color};
  },
  input: (provided) => {
    const color = '#fff';
    return {...provided, color};
  },
  menuList: base => ({
    ...base,
    padding: 0,
    marginTop: '-8px',
    backgroundColor: "#094650"
  })
}
